import React from 'react';
import userManualImage from './images/User-Manual.webp';

const UserManualSection: React.FC = () => {
    return (
        <section id="Manual" className="py-16 bg-secondary min-h-screen">
            <div className="container mx-auto flex flex-col lg:flex-row items-center">
                <div className="w-full lg:w-2/3 p-8 text-center lg:text-left">
                    <h2 className="text-4xl lg:text-6xl font-semibold mb-4 text-dark">
                        Download the User Manual
                    </h2>
                    <p className="text-lg lg:text-2xl text-dark mb-8">
                        You are feeling lost using our product, download the User Manual to get started.
                    </p>
                    <a
                        href="/documents/UserManual-UPhone9.pdf"
                        className="bg-primary text-white py-4 px-8 rounded-lg text-xl font-bold hover:bg-secondary hover:text-dark"
                        target='_blank'
                    >
                        Download
                    </a>
                </div>
                <div className="w-full lg:w-1/3 flex justify-center">
                    <div className="mx-10 mt-10 lg:mx-0 lg:mt-0">
                        <img src={userManualImage} alt="Download" className="w-full h-auto max-h-500 object-contain" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UserManualSection;
