// App.tsx
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import ProductSection from './Product';
import DownloadSection from './Download';
import UserManualSection from './Usermanual';
import TeamSection from './Team';
import ContactSection from './Contact';

const App: React.FC = () => {
    return (
        <div>
            <Navbar />
            
            <ProductSection />
            <DownloadSection />
            <UserManualSection />
            <TeamSection />
            <ContactSection />
            
            <Footer />
        </div>
    );
};

export default App;
