import React, { useState } from 'react';
import Logo from './images/Logo_Peach.svg';
import { Link, animateScroll as scroll } from 'react-scroll';

const Navbar: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const scrollToTop = () => {
        scroll.scrollToTop();
        setIsOpen(false);
    };

    return (
        <nav className="flex items-center justify-between flex-wrap bg-secondary p-6">
            <div className="flex items-center flex-shrink-0 text-white mr-6">
                <Link
                    to="product"
                    smooth={true}
                    duration={500}
                    onClick={scrollToTop}
                    className="flex items-center"
                >
                    <img src={Logo} alt="Logo" className="w-8 h-8 inline-block mr-2" />
                    <span className="font-semibold text-xl tracking-tight text-primary">PEACH</span>
                </Link>
            </div>
            <div className="block lg:hidden">
                <button className="flex items-center px-3 py-2 border rounded text-gray-800 border-dark hover:text-primary hover:border-white" onClick={toggleMenu}>
                    <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                </button>
            </div>
            <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? 'block' : 'hidden'}`}>
                <div className="text-sm lg:flex-grow">
                {[
                    { text: 'Product', id: 'product' },
                    { text: 'Download', id: 'download' },
                    { text: 'Manual', id: 'Manual' }, // Keep 'Manual' with uppercase 'M'
                    { text: 'Team', id: 'team' },
                    { text: 'Contact', id: 'contact' }
                ].map((item) => (
                    <Link
                        key={item.id}
                        to={item.id}
                        smooth={true}
                        duration={500}
                        onClick={() => setIsOpen(false)}
                        className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 hover:text-primary mr-4"
                    >
                        {item.text}
                    </Link>
                ))}
                </div>
                <a href="/Javadoc/index.html" target="_blank" rel="noreferrer" className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:bg-primary bg-dark mt-4 lg:mt-0">Javadoc</a>
            </div>
        </nav>
    );
};

export default Navbar;
