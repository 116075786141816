import React from 'react';

const ContactSection: React.FC = () => {
    return (
        <section id="contact" className="py-16 bg-secondary min-h-screen">
            <div className="container mx-auto text-center">
                <h2 className="text-4xl font-semibold mb-8 text-dark">Contact Us</h2>
                <p className="text-lg mb-8 text-dark">
                    Have any questions or feedback? We'd love to hear from you.
                </p>
                <form
                    className="max-w-lg mx-10 lg:mx-auto"
                >
                    <div className="mb-4">
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="mb-4">
                        <textarea
                            name="message"
                            placeholder="Message"
                            rows={4}
                            className="w-full px-3 py-2 border border-gray-300 rounded"
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="bg-primary text-white py-2 px-6 rounded hover:bg-dark"
                    >
                        Send
                    </button>
                </form>
                <div className="flex flex-wrap justify-center mb-8 mt-20">
                    <div className="company-info w-full md:w-1/2 md:pr-8 text-center">
                        <h3 className="text-2xl font-semibold mb-2 text-dark">The Peach Company</h3>
                        <p className="text-lg text-dark">Phone: +41 234 56 78</p>
                        <p className="text-lg text-dark">Email: <a href="mailto:info@peach.com">info@peach.com</a></p>
                        <p className="text-lg text-dark">Website: <a href="https://peach.gloor.dev" target="_blank" rel="noreferrer">peach.gloor.dev</a></p>
                        <p className="text-lg text-dark">Address: 123 Sweet Street, Juicyville, Fruitopia 56789, Fruitland</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
