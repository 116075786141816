import React from 'react';
import downloadImage from './images/Phone_2.webp';

const DownloadSection: React.FC = () => {
    return (
        <section id="download" className="py-16 bg-dark min-h-screen">
            <div className="container mx-auto flex flex-col lg:flex-row items-center">
                <div className="w-full lg:w-1/2 flex justify-center">
                    <img src={downloadImage} alt="Download" className="w-80 h-auto" />
                </div>
                <div className="w-full lg:w-1/2 p-8 text-center lg:text-left">
                    <h2 className="text-4xl lg:text-6xl font-semibold mb-4 text-light">
                        Download the Product
                    </h2>
                    <p className="text-lg lg:text-2xl text-light mb-8">
                        Get started with our amazing product now. Click the button below to download.
                    </p>
                    <a
                        href="/documents/UPhone9.zip"
                        download
                        className="bg-primary text-white py-4 px-8 rounded-lg text-xl font-bold hover:bg-secondary hover:text-dark"
                    >
                        Download
                    </a>
                    <small className='text-white text-muted ml-10 text-lg'>You should download it in the "D:/" folder</small>
                </div>
            </div>
        </section>
    );
};

export default DownloadSection;
