import React from 'react';
import GianLuca from './images/Gian-Luca.webp';
import Fabio from './images/Fabio.webp';
import David from './images/David.webp';
import Zepherin from './images/Zepherin.webp';
import Mathias from './images/Mathias.webp';

const TeamSection: React.FC = () => {
    const teamMembers = [
        { name: 'David Marsoni', role: 'Developer', image: David },
        { name: 'Mathias Pitteloud', role: 'Developer', image: Mathias },
        { name: 'Gian-Luca Gloor', role: 'Developer', image: GianLuca },
        { name: 'Fabio', role: 'Developer', image: Fabio },
        { name: 'Zepherin', role: 'Developer', image: Zepherin }
    ];

    return (
        <section id="team" className="py-16 bg-gray-800 text-white min-h-screen">
            <div className="container mx-auto px-4">
                <h2 className="text-5xl font-semibold text-center mb-12 text-white">Meet the Team</h2>
                <p className="text-lg lg:text-xl text-center text-gray-300 leading-relaxed mb-12 mx-4 lg:mx-0 text-justify">
    Welcome to the space where brilliance meets passion. Our team is a mosaic of innovators, problem solvers, and visionaries. Comprising skilled developers like David Marsoni, who is a coding wizard, and Mathias Pitteloud, whose analytical skills can turn any project into a masterstroke. Gian-Luca Gloor’s creativity and Fabio’s eye for detail make them the dynamic duo in web development. Zepherin, the coding maestro, is the powerhouse who ensures our codes are always crisp and efficient. Together, they form a formidable force that breaks the bounds of what’s possible and crafts the extraordinary. With a shared zeal to make the web-space more efficient, engaging, and user-friendly, their camaraderie transcends professional ties. Each line of code they write is woven with dedication and the desire to innovate. Our team doesn't just develop; they craft experiences, build dreams, and help ideas take flight in the digital world. Be a part of this wonderful journey and explore what it means to create without constraints.
</p>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-8">
                    {teamMembers.map((member, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-lg rounded-lg overflow-hidden transform transition-all duration-300 hover:scale-105"
                        >
                            <img
                                src={member.image}
                                alt={member.name}
                                className="object-cover object-center w-full h-48"
                            />
                            <div className="p-6 text-center">
                                <h3 className="text-2xl font-semibold text-gray-800 mt-2">{member.name}</h3>
                                <p className="text-gray-500 mb-2">{member.role}</p>
                            </div>
                        </div>
                    ))}
                </div>
                
                <div className="flex flex-col items-center mt-16">
                    <small className="mb-4 text-gray-300">Wanna join the team?</small>
                    <a href="https://youtu.be/SXHMnicI6Pg" className="bg-primary text-white py-3 px-8 rounded-lg text-lg font-bold hover:bg-opacity-90 transition-all duration-300">
                        Join the team
                    </a>
                </div>
            </div>
        </section>
    );
};

export default TeamSection;
