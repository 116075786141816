import React, { useState } from 'react';
import imgProduct from './images/Phone.webp';
import { Link, animateScroll as scroll } from 'react-scroll';

const ProductSection: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const scrollToTop = () => {
        scroll.scrollToTop();
        setIsOpen(false);
    };

    return (
        <section id="product" className="py-16 bg-secondary min-h-screen">
            <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center">
                <div className="w-full lg:w-1/2 p-8 text-center lg:text-left">
                    <h1 className="text-5xl lg:text-7xl font-bold mb-4 text-dark">
                        YOUR PHONE <br /> FOR <span className="text-primary">ALL</span> <br /> YOUR NEEDS
                    </h1>
                    <p className="text-lg lg:text-2xl mb-8 text-dark">
                        Not really, because they are only 6 apps.
                    </p>
                    <Link
                    to="download"
                    smooth={true}
                    duration={500}
                    onClick={scrollToTop}
                    className="bg-primary text-white py-4 px-8 rounded-lg text-xl font-bold hover:bg-dark"
                >
                    <a className="bg-primary text-white py-4 px-8 rounded-lg text-xl font-bold hover:bg-dark">Get the phone</a>
                </Link>
                    <p className="text-lg lg:text-2xl mt-8 text-dark">
                        A bit of a jerk around the edges to understand how the phone works? Download the User Manual!
                    </p>
                </div>
                <div className="w-full lg:w-1/2 flex justify-center">
                    <img src={imgProduct} alt="Product" className="w-80 lg:w-auto h-auto" />
                </div>
            </div>
        </section>
    );
};

export default ProductSection;
